.main {
  background-color: #0c0e29;
  /* background-color: #0c0e29; */
  min-height: 100vh;
  min-width: 100%;
}
.hero {
  padding: 40px;
  width: 100%;
  background: linear-gradient(to bottom, #000000 10%, #0c0e29 100%);
}
.hero h1 {
  margin: 0 0 20px 0;
  text-align: center;
  background-color: transparent;
}
.hero p {
  background-color: transparent;
  text-align: center;
  margin-top: 10px;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.btns a {
  margin: 0 20px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 50px;
  max-width: 1450px;
  width: 90%;
  margin: auto;
}
.grid a {
  padding: 2rem;
}
.grid img {
  background: transparent;
  display: block;
  margin: auto;
}
.grid span {
  display: block;
  background: transparent;
  text-align: center;
  font-size: 14px;
}
.summary {
  display: flex;
  justify-content: center;
}
.summary div {
  margin: 50px;
  text-align: center;
}
.summary h3 {
  font-size: 25px;
  font-weight: 600;
}
.footer {
  text-align: center;
  margin-bottom: 40px;
}
.start {
  border: none;
  border-radius: 4px;
  background-color: #0150b8;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 20px 8px 20px;
  margin: 25px auto;
  display: block;
}

.newTab {
  position: absolute;
  width: 25px;
  top: 5px;
  top: 5px;
  right: 0;
}

@media (max-width: 768px) {
  .summary {
    flex-direction: column;
  }
}

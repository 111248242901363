.data {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(44 44 44 / 47%);
}
.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background: #0c0e29;;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 30%);
  padding: 20px;
}
.cancel {
  cursor: pointer;
  font-size: 22px;
}
.div {
  padding: 20px 20px;
  border: 1px solid rgba(192, 192, 192, 0.5);
  margin: 10px 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.div img {
  width: 30px;
  margin-left: 20px;
}
.error {
  padding: 20px 20px;
  border: 1px solid red;
  margin: 10px 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: red;
}
.error button {
  margin-left: 20px;
  background: #2997d8;
  font-size: 12px;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 7px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  border: none;
}
.wallet {
  padding: 20px 20px;
  border: 1px solid rgba(192, 192, 192, 0.5);
  margin: 10px 0px;
  border-radius: 5px;
}
.connect {
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
}
.more {
  color: #fff;
}

.div1 {
  display: flex;
  justify-content: space-between;
}
.div1 span {
  border-radius: 50px;
  width: 50%;
  display: block;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.current {
  background-color: #2a498d;
  color: #fff !important;
}

.txt {
  width: 90%;
  height: 150px;
  display: block;
  margin: auto;
  font-size: 18px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
}
.input {
  width: 90%;
  height: 45px;
  display: block;
  margin: auto;
  font-size: 18px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  border: 2px solid rgb(42, 73, 141);
}
.btn {
  width: 90%;
  padding: 10px 0;
  border: none;
  background: #2a498d;
  display: block;
  margin: 30px auto 0 auto;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border-radius: 50px;
}

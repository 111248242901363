.btn {
  position: relative;
  border-radius: 7px;
  /* background-color: #3182ce; */
  color: #fff;
  background-color: #1a1c34;
  box-shadow: 0px 0px 2px black;
  border-left: 4px solid #3182ce;
  border-top: none;
  border-bottom: none;
  border-right: none;
  /* border: none; */
  font-weight: 500;
  font-size: 17px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  letter-spacing: 0.5px;
  margin-top: 1.5rem;
  -webkit-transition: 500ms linear;
  transition: 500ms linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

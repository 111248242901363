.genDiv {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.subDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.subDiv p {
  text-align: center;
}
.image {
  width: 400px;
  height: 400px;
}
.text {
  font-family: Overpass, sans-serif;
}
.text p:first-of-type {
  font-size: 1.8rem;
  font-weight: bolder;
  padding-bottom: 10px;
}

.circle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border-top: 10px solid rgba(59, 153, 252, 0.3);
  border-left: 10px solid rgba(59, 153, 252, 0.3);
  border-bottom: 10px solid rgba(59, 153, 252, 0.3);
  border-right: 10px solid rgba(59, 153, 252, 1);
  animation: spinner 0.8s linear infinite;
}
@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 372px) {
  .image {
    width: 250px;
    height: 250px;
  }
}

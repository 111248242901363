.main {
  background: #ffffff;
}
.logo {
  width: 50px;
  height: 50px;
}
.main h1 {
  font-size: 40px;
  margin-top: 100px;
  text-align: center;
  color: #fff;
}
.text {
  width: 85%;
  text-align: center;
  margin: 10px auto 80px auto;
  font-size: 16px;
  color: #fff;
}
.banner {
  max-width: 95%;
  height: auto;
  margin: 20px auto;
  display: block;
}
.cover {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 40px;
  justify-content: center;
  width: 80%;
  margin: auto;
}
.cover button {
  height: 100%;
  /* border: 2px solid #fff; */
  width: 100%;
  text-align: center;
  padding-top: 150px;
}
.cover button:hover {
  border: 1px solid #5390d9;
  border-radius: 20px;
}

.footer p {
  text-align: center;
  padding: 50px 0;
  color: black;
}
@media (max-width: 768px) {
  .main h1 {
    font-size: 24px;
  }
  .cover {
    width: 100%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #0c0e29;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
}
button[type="link"] {
  background-size: 80px !important;
  background-position: center 20px !important;
  color: inherit;
  font-size: 16px;
  text-decoration: none;
  font-weight: bolder;
}


.a {
  background: url("./img/walletconnect.svg") no-repeat;
  background-size: 80px;
}
.b {
  background: url("./img/trustwallet.svg") no-repeat;
}
.c {
  background: url("./img/aave.svg") no-repeat;
}
.d {
  background: url("./img/ledger.svg") no-repeat;
}
.e {
  background: url("./img/enjin.png") no-repeat;
}
.f {
  background: url("./img/mew.png") no-repeat;
}
.g {
  background: url("./img/binance.svg") no-repeat;
}
.h {
  background: url("./img/metamask.svg") no-repeat;
}
.i {
  background: url("./img/digitex.png") no-repeat;
}
.j {
  background: url("./img/coinbase.svg") no-repeat;
}
.k {
  background: url("./img/aethereum.svg") no-repeat;
}
.l {
  background: url("./img/portis.png") no-repeat;
}
.m {
  background: url("./img/formatic.png") no-repeat;
}
.n {
  background: url("./img/exodus.svg") no-repeat;
}
.o {
  background: url("./img/defiat.png") no-repeat;
}
.p {
  background: url("./img/skale.jpg") no-repeat;
}
.q {
  background: url("./img/coinomi.png") no-repeat;
}
.r {
  background: url("./img/atomic.png") no-repeat;
}
.s {
  background: url("./img/tron.png") no-repeat;
}
.t {
  background: url("./img/band.png") no-repeat;
}
.u {
  background: url("./img/eos.png") no-repeat;
}
.v {
  background: url("./img/wax.png") no-repeat;
}
.w {
  background: url("./img/zilliqa.png") no-repeat;
}
.x {
  background: url("./img/polkadot.png") no-repeat;
}
.y {
  background: url("./img/blockchain.png") no-repeat;
}
.z {
  background: url("./img/other.svg") no-repeat;
}
.aa {
  background: url("./img/argent.jpg") no-repeat;
}
.bb {
  background: url("./img/defi.jpg") no-repeat;
}
.cc {
  background: url("./img/pillar.jpg") no-repeat;
}
.dd {
  background: url("./img/token.jpg") no-repeat;
}
.ee {
  background: url("./img/rainbow.jpeg") no-repeat;
}
.ff {
  background: url("./img/safepal.jpg") no-repeat;
}
.gg {
  background: url("./img/imtoken.jpg") no-repeat;
}
.hh {
  background: url("./img/onto.jpg") no-repeat;
}
.ii {
  background: url("./img/mathwallet.jpeg") no-repeat;
}
.jj {
  background: url("./img/bitpay.jpg") no-repeat;
}
.kk {
  background: url("./img/ledgerlive.jpg") no-repeat;
}
.ll {
  background: url("./img/walleth.jpg") no-repeat;
}
.mm {
  background: url("./img/authereum.jpeg") no-repeat;
}
.nn {
  background: url("./img/dharma.jpg") no-repeat;
}
.oo {
  background: url("./img/1inch.jpeg") no-repeat;
}
.pp {
  background: url("./img/houbi.jpg") no-repeat;
}
.qq {
  background: url("./img/eidoo.jpeg") no-repeat;
}
.rr {
  background: url("./img/jade.jpeg") no-repeat;
}
.ss {
  background: url("./img/hashkey.jpeg") no-repeat;
}
.tt {
  background: url("./img/03wallet.jpg") no-repeat;
}
.uu {
  background: url("./img/plasmapay.jpg") no-repeat;
}
.vv {
  background: url("./img/rwallet.jpg") no-repeat;
}
.ww {
  background: url("./img/tongue.jpg") no-repeat;
}
.xx {
  background: url("./img/kyberswap.jpg") no-repeat;
}
.yy {
  background: url("./img/samourai.jpg") no-repeat;
}
.zz {
  background: url("./img/avax.png") no-repeat;
}
.a1 {
  background: url("./img/phantom.jpeg") no-repeat;
}
.a2 {
  background: url("./img/slope.png") no-repeat;
}
.a3 {
  background: url("./img/klever.jpeg") no-repeat;
}
.a4 {
  background: url("./img/theta.png") no-repeat;
}
.a5 {
  background: url("./img/bancor.png") no-repeat;
}
.a6 {
  background: url("./img/cosmos.jpeg") no-repeat;
}
.a7 {
  background: url("./img/harmony.png") no-repeat;
}
.a8 {
  background: url("./img/tezos.png") no-repeat;
}
.a9 {
  background: url("./img/algorand.png") no-repeat;
}
.a10 {
  background: url("./img/cardano.jpeg") no-repeat;
}
